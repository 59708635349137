// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-us-js": () => import("./../src/pages/AboutUs.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/Contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-donate-js": () => import("./../src/pages/Donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resources-js": () => import("./../src/pages/Resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-what-we-do-bibleclubs-js": () => import("./../src/pages/WhatWeDoBIBLECLUBS.js" /* webpackChunkName: "component---src-pages-what-we-do-bibleclubs-js" */),
  "component---src-pages-what-we-do-camps-js": () => import("./../src/pages/WhatWeDoCAMPS.js" /* webpackChunkName: "component---src-pages-what-we-do-camps-js" */),
  "component---src-pages-what-we-do-helpincommunities-js": () => import("./../src/pages/WhatWeDoHELPINCOMMUNITIES.js" /* webpackChunkName: "component---src-pages-what-we-do-helpincommunities-js" */),
  "component---src-pages-what-we-do-js": () => import("./../src/pages/WhatWeDo.js" /* webpackChunkName: "component---src-pages-what-we-do-js" */),
  "component---src-pages-what-we-do-training-js": () => import("./../src/pages/WhatWeDoTRAINING.js" /* webpackChunkName: "component---src-pages-what-we-do-training-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-resource-post-js": () => import("./../src/templates/resource-post.js" /* webpackChunkName: "component---src-templates-resource-post-js" */)
}

